import * as React from 'react'
import Layout from '../components/layout'

const contactPage = () => {
    return (
        <Layout pageTitle="Get in Touch">

        </Layout>
    )
  }
  
  export default contactPage